import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHandler } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {

  checked = false;

  constructor(
    public http: HttpClient
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const reqOpt: any = {};

    // reqOpt.params = req.params.set('platformType', 'GPAY');
    let h = req.headers.set('authorization', '3ed3c5cd8884899dc77940c8017a7cfe');
    h = h.set('appType', 'FOSOnboard');
    reqOpt.headers = h;

    const reqClone = req.clone(reqOpt);
    return next.handle(reqClone)
      .pipe(
        tap(
          (data) => { },
          (err) => {
            if (err.status === 401 && this.checked === false) {
              this.checked = true;
            }
          }
        )
      );
  }
}
